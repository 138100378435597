:root {
    --header-max-height: 66px;
    --small-device-size: 737px
}

.App-header {
    background-color: white;
    color: var(--primary-text-color);
    height: 65px;
    position: fixed;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    z-index: 1000;
    border-bottom: #c9c9c9 1px solid;
}

.App-header a {
    color: #111;
}


.App-header.darken {
    background-color: #433F3D;
    color: #fff;
    border-bottom: #2f2d2d 1px solid;

    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.App-header.darken a {
    color: #fff;
}

.App-nav {
    flex: 0.7;
    padding-right: 1em;
    justify-content: flex-end;
    display: flex;
}

.App-menu {
    flex: 0.5;
}

.App-menu__right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    flex: 0.5;
}

.App-menu__right a {
    display: flex;
    align-items: center;
}

.App-menu__right a:nth-child(n+1) {
    margin-left: 1em;
}

.App-content {
}

.App-name {
    font-size: 25px;
    letter-spacing: 8px;
    padding-left: 18px;
}

.App-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 1em;
    flex: 0.3;
}

.App-logo {
    height: var(--header-max-height);
    pointer-events: none;
}

nav ul {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    flex: 0.5;
}

.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: var(--primary-color);
    z-index: 10;
}

.sidenav.fixed {

    display: flex;
    align-items: center;
}

nav ul {
    display: flex;
    margin-bottom: 0;
}

nav ul li {
    list-style-type: none;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    margin-right: 1em;
}

nav a {
    display: block;
    text-decoration: none;

    color: #db4437;
    font-size: large;
    letter-spacing: 1px;
}

nav a:hover {
    color: #D0541A;
}

@media screen and (max-width: 1045px) {
    nav.fixed ul li a {
        padding: 1em;
        font-size: 16px;
    }
}

@media screen and (max-width: 700px) {
    nav a {
        font-size: 16px;
    }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 610px) {
    nav a {
        padding: 1em;
        font-size: 16px;
    }
}

@media screen and (max-width: 574px) {
    nav a {
        padding: 1em;
        font-size: 14px;
    }
}

@media only screen and (max-width: 431px) {

    .nav-text {
        font-size: 2.2rem !important;
    }
}

@media only screen and (max-width: 737px) {

    .App-header {
        position: fixed;
        width: 100%;
        z-index: 100;
        flex-direction: row;
        justify-content: flex-start;
        height: 55px;
        background-image: none !important;
    }

    .App-menu {
        display: none;
    }

    .App-content {
        right: 0;
        left: 0;
        bottom: 0;
    }

    #App-menu-icon {
        cursor: pointer;
        width: 48px;
        height: 55px;
    }

    #App-menu-icon:after {
        content: '';
        position: absolute;
        display: block;
        width: 18px;
        height: 0px;
        top: 12px;
        left: 18px;
        box-shadow: 0 10px 0 1px var(--red), 0 16px 0 1px var(--red), 0 22px 0 1px var(--red);
    }

    .App-menu__right {
        flex-direction: column;
        flex: none;
        justify-content: start;
        align-items: center;
        background-color: #eeeeee;
    }

    .App-menu__right a:nth-child(n+1) {
        flex-direction: column;
        margin-top: 1em;
        margin-left: 0;

    }

    .App-menu__right a {
        width: 200px;
    }

    .App-logo {
        display: none;
    }

    .App-name {
        width: 100%;
        padding-bottom: 0;
        font-size: 20px;
        padding-left: 0;
    }

    .App-brand {
        padding-left: 0.3em;
        padding-right: 0;
        flex: 1;
    }

    .App-nav {
        height: 100%; /* 100% Full-height */
        width: 0; /* 0 width - change this with JavaScript */
        position: fixed; /* Stay in place */
        z-index: 1; /* Stay on top */
        top: 56px; /* Stay at the top */
        left: 0;
        background-color: var(--dark-gray); /* Black*/
        overflow-x: hidden; /* Disable horizontal scroll */
        transition: 0.2s; /* 0.5 second transition effect to slide in the sidenav */;
        padding-right: 0;
    }

    .App-nav.fixed {
        display: block;
    }

    .with--sidebar .App-nav {
        width: 250px;
    }

    .App-nav ul {

        display: flex;
        flex-direction: column;
        text-decoration: none;
    }

    .with--sidebar .site-cache {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right:  0;

        z-index: 10;
        background-color: rgba(0,0,0,0.6);
    }

    body.with--sidebar {
        overflow-x: hidden;
        overflow-y: hidden;
    }
}