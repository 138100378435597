#quotation-page {
    display: flex;
    flex-direction: row;
    /* padding-left: 8em; */
    /* padding-right: 8em; */
}

@media only screen and (max-width: 737px) {
    #quotation-page {
        display: flex;
        flex-direction: column;
        padding-left: 1em;
        padding-right: 1em;
    }
}