.static-element {
    color: #777;
    background-color: white;
    padding: 50px 80px;
    text-align: justify;
    position: relative;

    font-size: 20px;
}

.static-element img {
    flex: 0.5;
}

.parallax-background {
    position: relative;
    opacity: 0.8;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallax-title {
    position: absolute;
    left: 0;
    top: 20%;
    text-align: center;

    background-color: #fff;
    color: #111;
    padding: 18px;
    font-size: 25px;
    letter-spacing: 8px;
}

.parallax-subtitle {
    position: absolute;
    left: 0;
    top: 28%;
    color: #fff;
    padding: 18px;
    font-size: 25px;
    letter-spacing: 3px;
    max-width: 35%;
}

.parallax-text {

    position: absolute;
    left: 0;
    color: #fff;
    max-width: 35%;
    padding-left: 18px;
    font-size: 20px;
    letter-spacing: 1px;
}

.parallax-text div:nth-child(2){
    margin-left: 1em;
}

@media only screen and (max-width: 737px) {

    .parallax-background {
        background-attachment: unset;
        background-position: top;
        background-repeat: no-repeat;
     /*   background-size: cover;*/
    }


    .static-element {
        color: #777;
        background-color: white;
        padding: 1em 1em 2em 1em;
        text-align: left;
        position: relative;
        font-size: 16px;
    }

    .parallax-subtitle {
        max-width: 60%;
    }

    .parallax-text {

        font-size: 16px;
        max-width: 90%;
    }

    .parallax-background.in-height {
        height: 100%;
    }
}