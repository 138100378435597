input {
    outline: none;
    background: #FFFFFF;
    border: 0.5px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 5px;
    height: 37px;
    padding-left: 1em;
}

.field-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    flex: 1;
    min-width: 47%;
}

.field-container label {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;

}

textarea {
    outline: none;
    resize: none;
    background: #FFFFFF;
    border: 0.5px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0.5em 1em;
    font-family: 'Muli', sans-serif;
}

/*.form-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 2em;
}

.form-container .form {
    display: flex;
    flex-wrap: wrap;
}*/

.error-block {
    padding-bottom: 1em;
    color: #db4437;
}

.form-hook span {
    display: block;
    padding-left: 1.4em;
    padding-right: 2em;
}

@media only screen and (max-width: 737px) {
    .form-container {
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 1em;
    }

    .form-hook {
        flex: 0;
    }

    .form-hook span {
        padding-left: 0;
        padding-right: 0;
    }
}