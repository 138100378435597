#login-page {
    display: flex;
    flex-direction: row;
    padding-left: 4em;
    padding-right: 4em;
}

@media only screen and (max-width: 737px) {
    #login-page {
        display: flex;
        flex-direction: column;
        padding-left: 1em;
        padding-right: 1em;
    }
}