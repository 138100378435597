div .fade {
    opacity: 0;

    transition: opacity .5s ease-in;
}

div {
    opacity: 1;
}

.offer-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.offer-item {
    padding: 1em;
    text-align: center;
    vertical-align: top;
}