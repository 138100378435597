// fonts
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:400,300,300italic,400italic,600,700,600italic,700italic);

.demo-title {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 50px;
}

// variables
$icon-size: 3rem;
$icon-color: #D8D8D8;
$main-color: #db4437;

// Pricing table
.pricing-table {
  display: table;
  padding-top: 2em;
  width: 100%;

  .pricing-option {
    width: 28%;
    background: white;
    float: left;
    padding: 2%;
    text-align: center;
    transition: all .3s ease-in-out;

    &:nth-child(even) {
      margin: 0 2%;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.3);
      transform: scale(1.04);

      i, h1, span, b {
        color: $main-color;
      }

      .front {
        opacity: 0;
        visibility: hidden;
      }

      .back {
        opacity: 1 !important;
        visibility: visible !important;

        a.button {
          transform: translateY(0px) !important;
        }
      }
    }

    hr {
      border: none;
      border-bottom: 1px solid #F0F0F0;
    }

    i {
      font-size: $icon-size;
      color: $icon-color;
      transition: all .3s ease-in-out;
    }

    h1 {
      margin: 10px 0;
      color: #212121;
      transition: all .3s ease-in-out;
    }

    p {
      color: #999;
      padding: 0 10px;
      line-height: 1.3;
    }

    .price {
      position: relative;

      .front {
        span.price {
          font-size: 2rem;
          margin-top: 20px;
          display: block;
          font-weight: 700;
          position: relative;

          b {
            position: absolute;
            font-size: 1rem;
            margin-left: 2px;
            font-weight: 600;
          }
        }
      }

      .back {
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-in-out;

        a.button {
          background: $main-color;
          padding: 15px 20px;
          display: inline-block;
          text-decoration: none;
          color: white;
          position: absolute;
          font-size: 13px;
          top: -5px;
          left: 0;
          right: 0;
          width: 150px;
          margin: auto;
          text-transform: uppercase;
          transform: translateY(20px);
          transition: all .3s ease-in-out;

          &:hover {
            background: darken($main-color, 10%);
          }
        }
      }
    }
  }
}


// Responsive
@media screen and (max-width: 737px) {
  .pricing-table {
    .pricing-option {
      padding: 5%;
      width: 90%;

      i {
        display: none;
      }

      p {
        font-size: larger;
      }

      &:hover {
        cursor: initial;
        box-shadow: none;
        transform: none;
      }

      &:nth-child(even) {
        margin: 30px 0 !important;
      }

      .price {
        display: flex;
        flex-direction: column;
        position: relative;

        .front {
          opacity: 1;
          visibility: visible;

          span.price {
            margin-top: 0;
          }
        }

        .back {
          opacity: 1 !important;
          visibility: visible !important;

          a.button {
            position: relative;
            transform: none;
            transition: none;
          }
        }
      }
    }
  }
}