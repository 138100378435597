.form-container {

    flex-direction: column;
    align-items: center;
}

.form-container form {
    flex: 0.4;
    padding: 2em;
    width: 100%;
    align-self: center;
}

.form-hook {
    flex: 0.6
}

.form-hook span {
    display: block;
    padding-left: 1.4em;
    padding-right: 2em;
}

input.field {

}

@media only screen and (max-width: 737px) {

    .form-container {
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 1em;
    }

    .form-container form {
        padding: 0;
    }

    .form-hook {
        flex: 0;
        padding-bottom: 2em;
    }

    .form-hook span {
        padding-left: 0;
        padding-right: 0;
    }
}