.App {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-name {

}

.App-content {
  height: 100%;
  background-color: var(--light-gray);
  color: var(--secondary-text-color);
}

.App-footer {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
}

.Footer-app-name {
  padding-top: 1em;
  display: flex;
  justify-content: center;
}

.Footer-app-hook {
  text-align: center;
}

.App-link {
  color: var(--link-color);
}

.page-content {
  padding-top: var(--header-max-height);
  max-width: 1024px;
  margin: auto;
}

.page {
  display: flex;
  flex: 1 1;
  padding: 1em 2em 3em 3em;
  width: 100%;
  background: white;
  margin-top: 2em;
  margin-bottom: 2em;
}

.error-block {
  text-align: center;
  padding-bottom: 1em;
  color: var(--red)
}

ul {
  list-style-type: circle
}

li {
  margin-bottom: 0.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Transition */

.header-appear {
  background-color: #db4437 !important;
  max-width: 20%;
  animation: grow0 1.5s forwards;
  -webkit-transition: max-width 0.2s;
  -moz-transition: max-width 0.2s;
  -o-transition: max-width 0.2s;
  transition: max-width 0.2s;
}

.header-appear .header-appear-active {
  max-width: 100%;

}

@media only screen and (max-width: 737px) {

  .page {
    display: flex;
    flex: 1 1;
    padding: 0;
    width: 100%;
    background: white;
    margin: 0;
  }

  .page-content {
    padding-right: 1em;
    padding-left: 1em;
    padding-bottom: 2em;
  }
}