@font-face {
  font-family: 'Roboto';
  src: url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli';
  src: url(./fonts/Muli.ttf) format('truetype');
}

:root {
  --primary-color:white;
  --primary-text-color: black;

  --secondary-color: white;
  --secondary-text-color: black;

  --link-color: green;
  --link-pressed-color: darkgreen;

  --green: #388e3c;
  --dark-gray: #433F3D;
  --red: #db4437;

  --light-gray: #F9F9FD;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Muli', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 17px;

  background-color: var(--light-gray);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: var(--red);
}

.img-responsive {
  width: 100%;
  height: auto;
}

.img-responsive.in-height {
  width: auto;
  height: 100%;
}

.light-greyed {
  background-color: #EFEEEC;
}

@media only screen and (max-width: 737px) {


  .mobile-hide {
    display: none !important;
  }

  h1 {
    font-size: 1.5em;
  }

}
